<template>
  <v-container>
    <v-row class="ma-0 pa-0">
      <v-col
        class="ma-0 pa-0"
        cols="12"
      >
        <v-card>
          <v-toolbar
            dark
            dense
            color="amber"
          >
            <v-col cols="1">
              <v-avatar
                tile
                dense
                size="55"
                color="amber lighten-2"
                class="avatar rounded-lg"
              >
                <v-icon
                  color="white"
                  x-large
                >
                  mdi-book
                </v-icon>
              </v-avatar>
            </v-col>
            <h3 class="mx-5">
              Órdenes
            </h3>
            <v-spacer />

            <v-col cols="3">
              <v-text-field
                v-model="search"
                dense
                solo-inverted
                dark
                color="red"
                class="mx-2"
                append-icon="mdi-magnify"
                label="Filtrar"
                single-line
                hide-details
              />
            </v-col>

            <v-btn
              small
              class="ma-4"
              color="success"
              @click="consultar"
            >
              Consultar <v-icon>mdi-update</v-icon>
            </v-btn>

            <v-btn
              align="right"
              color="orange"
              dark
              small
              class="ma-2"
              @click.stop="addorden"
            >
              Nuevo <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>

        <v-card elevation="10">
          <v-data-table
            heigth="350"
            :headers="headers"
            :items="misordenes"
            :search="search"
            dense
          >
            <template v-slot:item="{ item }">
              <tr @dblclick="editar(item)">
                <td>{{ item.idorden }}</td>
                <td>{{ item.modulo }}</td>
                <td>
                  {{ item.nomcont }}
                  {{ item.empresa }}
                </td>

                <td>{{ item.titulo }}</td>
                <td>{{ item.problema }}</td>
                <td class="text--red">
                  {{ item.fechacorta }}
                  {{ item.hrcreada }}
                </td>

                <td>
                  <v-icon
                    color="red"
                    class="ma-1 pa-1"
                    @click="regactividad(item)"
                  >
                    mdi-pencil
                  </v-icon>

                  <v-simple-checkbox
                    v-model="item.prioridad"
                    disabled
                    class="ma-0 pa-0"
                  />
                </td>

                <!-- <td>
                  <v-simple-checkbox
                    v-model="item.prioridad"
                    disabled
                  />
                </td> -->
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  
  data() {
    return {
      drawer: false,
      search: '',
      misordenes: [],
      headers: [
        { text: 'Id', value: 'idorden', align: 'end', sortable: false },

        { text: 'Tipo', value: 'modulo' },
        { text: 'Contacto', value: 'nomcont' },
        // { text: 'Empresa', value: 'empresa' },
        { text: 'Titulo', value: 'titulo' },
        { text: 'Problema', value: 'obs' },
        { text: 'Fecha', align: 'start', value: 'substr(fechacorta,0,10)' },
        { text: 'Registrar Actividad', value: 'actions', sortable: false }
        // { text: 'Urgencia', align: 'end', value: 'prioridad' },
      ]
    }
  },

  computed: {
    ...mapGetters('login', ['getdatosUsuario'])
  },

  created() {
    this.consultar()
  },

  methods: {
    ...mapActions('oportunidad', ['setOportunidad']),
    consultar() {
      // limpiar
      this.misordenes = []
      console.log('getdatosUsuario', this.getdatosUsuario)

      let id = this.getdatosUsuario.idusuariosweb
      console.log('consultar', id)

      this.$http
        .get('v2/misordenes/' + id)
        .then(response => {
          if (response.data.error === null) {
            console.log(response)
            this.misordenes = response.data.result

            response.data.result.forEach(element => {
              let cFecha = new Date(element.creada).toLocaleDateString(
                'es-MX',
                {
                  timeZone: 'UTC',
                  month: 'short',
                  day: 'numeric'
                }
              )
              element.fechacorta = cFecha
              element.prioridad = Boolean(element.prioridad)

              element.fecha = element.fecha.substr(0, 10)
              // element.hrcreada = element.hrcreada.substr(0,6)
              element.actions = ''
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    editar(item) {
      console.log('Renglonitem', item)
      this.$router.push({ name: 'addorden', params: { addorden: item } })
    },

    regactividad(item) {
      console.log('registrar actividad', item)
      this.$router.push({ name: 'actividad', params: { addorden: item } })
    },

    addorden() {
      this.$router.push({ name: 'addorden' })
    }
  }
}
</script>

<style scoped>
.avatar {
  top: -10px;
}
</style>
